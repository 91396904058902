import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WorkInfo from "../../components/work-info"
import { StaticImage } from "gatsby-plugin-image"
import ProjectCard from "../../components/project-card"
import JackRabbit from "../../images/projects/jack-rabbit/cover.jpg"
import LatimerApp from "../../images/projects/latimer-app/cover.jpg"

const aurisPage = () => (
  <Layout>
    <SEO title="ONYX Design" />
    <WorkInfo 
      title="ONYX Design"
      link="onyxdesign.co"
      team1="Bailey Latimer"
      challenge="Rebrand + portfolio redesign for a web development agency based in Los Angeles."
      solution="Rebrand + portfolio redesign for a web development agency based in Los Angeles."/>

    <StaticImage class="mb-6" src="../../images/projects/onyx-design/cover.jpg" alt="ONYX Design Hero" aspectRatio={16/9} objectPosition={"50% 30%"} layout={"fullWidth"}/>

      <StaticImage  class="mb-6" src="../../images/projects/onyx-design/inspo.jpg" alt="ONYX Design Moodboard"  layout={"fullWidth"}/>
      <StaticImage  class="mb-6"src="../../images/projects/onyx-design/browser.jpg" alt="ONYX Design Browser Preview"  layout={"fullWidth"}/>
        
      <StaticImage class="mb-6" src="../../images/projects/onyx-design/home--desktop.jpg" alt="ONYX Design Home Preview" />

      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/onyx-design/logo--before.jpg" alt="ONYX Design Logo Before"  layout={"fullWidth"}/>
        <StaticImage class="mb-6" src="../../images/projects/onyx-design/logo--after.jpg" alt="ONYX Design Logo After"  layout={"fullWidth"}/>
      </div>

      <StaticImage class="mb-20"src="../../images/projects/onyx-design/brand-01.jpg" alt="ONYX Design Typography" layout={"fullWidth"}/>

      <div class="adv-gallery-v3 mb-20">
        <h4 className="color-contrast-higher mb-4 pl-8 lg:pl-0">Related</h4>
        <ul class="columns-1 lg:columns-2">
        <ProjectCard classes="" title="JackRabbit" url="jackrabbit"  description="Making commuting fun again." cover={JackRabbit}  role="Development"/>
        <ProjectCard classes="" title="latimer.app" url="latimer-app"  description="An experiment to build the fastest e-commerce site possible using Gatsby.js + Shopify." cover={LatimerApp}  role="Design + Development + Branding"/>
        </ul>
      </div>

  </Layout>
)

export default aurisPage
